import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { Body } from 'components/Text/Body';
import { CmsApiBlockquoteBlockData } from 'modules/cms/api/types/blocks/CmsApiBlockquoteBlock';
import { colors, gutterWidth } from 'theme/theme';

type Props = {
  blockIndex: number;
  data: CmsApiBlockquoteBlockData;
  className?: string;
};

export const CmsBlockquoteBlock = styled(
  ({ blockIndex, data: { quote, cite, style }, className }: Props) => (
    <div>
      <Box
        mx={[-gutterWidth / 2, null, -gutterWidth]}
        width={[
          `calc(100% + ${gutterWidth}px)`,
          null,
          `calc(100% + ${gutterWidth * 2}px)`,
        ]}
        borderRadius={[0, 0, 0, 10]}
        bg={colors.backgroundGrey}
        className={className}
      >
        <Box
          px={[gutterWidth / 2, null, gutterWidth]}
          py={60}
          textAlign={style === 'QUOTE' ? 'center' : 'left'}
        >
          <Body
            analyticsData={{
              eventName: 'CMS Block',
              eventProperties: {
                block_position: blockIndex,
                block_type: 'Block Quote',
              },
            }}
            styleAs={quote.length > 225 ? 'body' : 'bodyLarge'}
            dangerouslySetInnerHTML={{
              __html: quote,
            }}
          />
          {cite && (
            <Box mt="18px">
              <Body>{cite}</Body>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  ),
)`
  position: relative;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
  ${(props) =>
    props.data.style === 'QUOTE' &&
    `
      &::before {
        content: '\\201c';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -35%);
        color: ${colors.brandYellow};
        font-size: 100px;
        font-weight: 700;
        line-height: 1;
    }
  `}
  ${(props) =>
    props.data.style === 'CALLOUT' &&
    `
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 34px;
        left: 48px;
        background-color: ${colors.brandYellow};
        height: 5px;
        width: 70px;
    }
  `}
`;
